import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "../../../../utility/axios"

let axiosController = new AbortController()

export const fetchAgents = createAsyncThunk(
    "agentChat/fetchAgents",
    async () => {
        const response = await axios.get('/api/AgentMessage/GetUsers')
        if (response.data['succeeded']) return response.data.data
    }
)

export const loadMoreMessages = createAsyncThunk(
    'agentChat/loadMoreMessages',
    async ({chatId, page}) => {
        const response = await axios.get('/api/message/', {
            params: {contactId: chatId, pageNumber: page},
            signal: axiosController.signal
        })
        if (response.data['succeeded']) return {id: chatId, ...response.data.data}
    })

export const selectChatForAgent = createAsyncThunk('agentChat/selectChat', async (
    {agentId, groupId, isGroup, agent, canRead}, {getState}
) => {
    console.log(isGroup, agent, canRead)
    const chatHistory = getState().agentChat.history
    const history = chatHistory.find(_chat => _chat.id === agentId)

    console.log("history", history)
    // Return from history
    if (history) {
        console.log('can read', canRead)
        // if (canRead) axios.post('api/contact/markAsRead', {
        //     contactId: agentId
        // }).then().catch(error => console.log(error))
        return {id: agentId, isGroup, agent, history}
    }

    // Fetch and return
    const response = await axios.get('/api/AgentMessage', {
        params: {userId: agentId, groupId},
        signal: axiosController.signal
    })

    console.log("response->", response.data)

    if (response.data['succeeded']) {
        // if (canRead) axios.post('api/contact/markAsRead', {
        //     contactId: agentId
        // }).then().catch(error => console.log(error))

        // const _response = await axios.get('/api/message/getPinnedMessages', {
        //     params: {contactId: agentId}
        // })

        console.log("response->", response)
        return {id: agentId, isGroup, agent, ...response.data.data}
    }
})

// const chatListSort = (a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate)
const messagesSort = (a, b) => new Date(a['createdDate']) - new Date(b['createdDate'])

export const agentChatSlice = createSlice({
    name: "agentChat",
    initialState: {
        agentList: {
            items: [],
            paginator: {
                current: 1,
                hasNext: false
            }
        },
        activeChat: {
            isReady: false,
            id: null,
            isGroup: false,
            agent: null,
            pinnedMessages: [],
            messages: [],
            paginator: {
                current: 1,
                hasNext: false
            }
        },
        history: [],
        receiver: {},
        unreadMessageCount: 0
    },
    reducers: {
        deleteMessage: {
            reducer(state, action) {
                console.log("deleting..", state.activeChat.id, action.payload.contactId)

                console.log("----", state.activeChat.id)
                if (state.activeChat.id === action.payload.contactId) {
                    console.log("deleting..")
                    state.activeChat.messages = state.activeChat.messages.filter(
                        _msg => _msg.id !== action.payload.messageId
                    )
                } else {
                    const chat = state.history.find(_item => _item.id === action.payload.contactId)
                    if (chat) chat.messages = chat.messages.filter(
                        _msg => _msg.id !== action.payload.messageId
                    )
                }
            },
            prepare(contactId, messageId) {
                console.log("prepare delete", contactId, messageId)
                return {
                    payload: {contactId, messageId}
                }
            }
        },
        newMessage: {
            reducer(state, action) {
                // if (state.activeChat.id === action.payload.contactId)
                console.log("newMessage", action.payload)
                state.activeChat.messages.push(action.payload)
                // else {
                //   const chat = state.history.find(_item => _item.id === action.payload.contactId)
                //   if (chat) chat.messages.push(action.payload)
                // }
            },
            prepare(data) {
                return {
                    payload: data
                }
            }
        },
        resetActiveChat: (state) => {
            axiosController.abort()

            const activeChat = state.activeChat
            const messageCount = activeChat.messages.length
            const slice = messageCount >= 25 ? -25 : -messageCount
            const messages = activeChat.messages.slice(slice)

            if (activeChat.id) {
                let found = false
                state.history = state.history.map(_chat => {
                    if (_chat.id === activeChat.id) {
                        found = true
                        return {
                            isReady: true,
                            id: activeChat.id,
                            isGroup: activeChat.isGroup,
                            pinnedMessages: activeChat.pinnedMessages,
                            paginator: activeChat.paginator,
                            messages
                        }
                    } else return _chat
                })
                if (!found) state.history.push({
                    ...state.activeChat,
                    messages
                })
            }

            state.activeChat = {
                isReady: false,
                id: null,
                isGroup: false,
                pinnedMessages: [],
                messages: [],
                paginator: {
                    current: 1,
                    hasNext: false
                }
            }
            axiosController = new AbortController()
        },
        setReceiver: {
            reducer(state, action) {
                state.receiver = action.payload
            },
            prepare(id, fullName, profileImage, online) {
                return {
                    payload: {id, fullName, profileImage, online}
                }
            }
        },
        unsetReceiver: (state) => {
            state.receiver = {}
        },
        setUnReadMessageCount: (state, action) => {
            state.unreadMessageCount = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgents.fulfilled, (state, action) => {
                console.log("action.payload.items", action.payload)
                state.agentList.items = action.payload || []
            })
            .addCase(selectChatForAgent.fulfilled, (state, action) => {
                console.log("selectChatForAgent.fulfilled", action.payload)

                if (action.payload.history) {
                    state.activeChat = {...action.payload.history, agent: action.payload.agent}
                } else {
                    state.activeChat.isReady = true
                    state.activeChat.agent = action.payload.agent
                    state.activeChat.id = action.payload.id
                    state.activeChat.isGroup = action.payload.isGroup
                    state.activeChat.messages = action.payload.items
                    state.activeChat.pinnedMessages = action.payload.pinnedMessages
                    state.activeChat.paginator = {
                        current: action.payload.pageNumber,
                        hasNext: action.payload.hasNextPage
                    }
                    state.activeChat.messages.sort(messagesSort)
                }
            })
    }
})

export const {
    newMessage,
    deleteMessage,
    setReceiver,
    resetActiveChat,
    setUnReadMessageCount
} = agentChatSlice.actions

export const getUnReadMessageCount = state => state.agentChat.unreadMessageCount
export const getAgentList = state => state.agentChat.agentList
export const getActiveChat = state => state.agentChat.activeChat
export const getReceiver = state => state.agentChat.receiver
export default agentChatSlice.reducer